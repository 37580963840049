/* eslint-disable */
import boy1 from './boy1.PNG'
import boy2 from './boy2.PNG'



export const testimonialsData = [
    {
        id: 1,
        name: 'Anthony Aguilar',
        title: 'Cliente',
        text: "5/5: Este queso es el queso mas sabroso de todo San Chepe",
        image: boy1
    },
    {
        id: 2,
        name: 'Abel Aguilar',
        title: 'Cliente',
        text: "5/5: el mejor queso de mexico, gracias omar por tu ayuda",
        image: boy2
    },
    {
        id: 3,
        name: 'Christian Verdugo',
        title: 'Cliente',
        text: "5/5: Tiene el más rico queso. Omar tiene mejor servicio a cliente.",
        image: boy1
    },
]
