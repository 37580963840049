import logo1 from './cheese.png';
import logo2 from './loc.jpg';
import logo3 from './machine.jpg';
import logo4 from './morecheese.jpg';

const images = [{
    id: 1,
    src: logo1,
    alt: "Image 1"
},
{
    id: 2,
    src: logo2,
    alt: "Image 2 "
},
{
    id: 3,
    src: logo3,
    alt: "Image 3"
},
{
    id: 4,
    src: logo4,
    alt: "Image 4 "
}
];
export default images;
